<template>
    <div class="flex flex-col bg-white rounded py-4"
         :class="{
                'w-full': isSetup,
                'lg:w-[400px] px-6': !isSetup
            }"
    >
        <img :src="logoBlack" alt="" class="w-32 mx-auto mb-5" v-if="!isSetup"/>

        <p class="text-3xl font-bold text-black mb-5">
            Starter
        </p>

        <table class="text-left align-middle">
            <tbody>
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                    Local Phone Number with your Area Code.
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                    1 Agent
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <MdiIcon :icon="mdiCloseCircleOutline" class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span class="text-blue-500 cursor-help" v-tooltip.bottom="`Workflows are the heart of your bot. They can take in caller information,
                                            fill spreadsheets, schedule appointments, send emails and much more. These
                                            are some ideas for call workflows to get you started.`">
                                            No Workflows
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Access to all Voices.
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Receive Calls from your Bot, Take Messages, Send Text Message, and more.
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            <b>60 Minutes Included</b>, additional minutes 30 cents per minute.
                                        </span>
                </td>
            </tr>
            </tbody>
        </table>

        <hr class="mt-auto mb-3">

        <p
            class=""
            :class="{
                'text-3xl': isSetup,
                'text-4xl': !isSetup
            }"
        >
            {{ products[0].formatted_price }}/mo
        </p>

        <p class="text-sm">
            + 30 cents per minute (60 mins included)
        </p>

        <div class="py-4 lg:py-8 flex items-center justify-center gap-x-6">
            <PrimaryButton
                v-if="!isSetup"
                @click="() => router.visit(route('register.with-price', {price: products[0].value}), { method: 'get' })"
                class="scale-125 lg:scale-150 origin-center">
                Start your Free Trial
            </PrimaryButton>

            <PrimaryButton
                v-if="isSetup"
                @click="emit('submit', products[0].value)"
                :disabled="loading"
                :class="{
                    'opacity-25': loading
                }"
                class="lg:scale-125"
            >
                Start Free Trial
            </PrimaryButton>
        </div>
        <p class="text-xs lg:-mt-5" v-if="!isSetup">
            Start your free, no-obligation 5 day trial today.
        </p>
    </div>

</template>
<script setup lang="ts">
import logoBlack from "../../../../images/logo-black.svg";
import {router, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {CheckCircleIcon} from "@heroicons/vue/20/solid/index";
import {route} from "ziggy-js";
import MdiIcon from "@/Components/MdiIcon.vue";
import {mdiCloseCircle, mdiCloseCircleOutline} from "@mdi/js";

const products = usePage().props.products

defineProps(['isSetup', 'loading'])

const emit = defineEmits(['submit'])
</script>
